
import React from 'react';
import { getZI, isBrowser, resetZI, setUserIdentities, setZI, unsetUserIdentities } from '../../utils/zeotap';
import LogLink from '../components/LogLink';

const initialState = {
  fpuid: '', email: '', cellno: '', loginid: ''
}

class Identities extends React.Component {
  state = {...initialState, zi : ''};
  decodedCookieDetails = {};
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value})
  }

  getCookie = (cname) => {
    try {
      var name = cname + "=";
      var value = null;
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          value = c.substring(name.length, c.length);
        }
      }
      return value;
    } catch (e) {
      return null;
    }    
  }

  base64Encode = (value) => (isBrowser() && window.btoa(unescape(encodeURIComponent(value))))

  base64Decode = (value) => (decodeURIComponent(escape(atob(value))))

  getDecodedValue = (value)=>{
    try{
      var ob = JSON.parse(this.base64Decode(value));
      if(typeof ob !== 'object') return null
      return ob
    }
    catch(err){}
    return null
  }

  getUserIdentities = async() => {
    const write_key = isBrowser() && window.zeotapConfig && window.zeotapConfig.wk;
    var suffix = window.location.hostname.search('zeotap.com')>-1 ? 'zeotap.com':""
    const identity_cookie = 'zpstorage_' + this.base64Encode(write_key)+ 'identity' + suffix;
    const encodedUserIdentities = this.getCookie(identity_cookie);
    const userIdentities = this.getDecodedValue(encodedUserIdentities);
    this.decodedCookieDetails= {...userIdentities};
    this.setState({...this.state, zi: getZI()});
    console.log(this.decodedCookieDetails);
  }

  onSetIdentities = () => {
    setUserIdentities({fpuid: this.state.fpuid, email:this.state.email, loginid:this.state.loginid, cellno: this.state.cellno});
    this.getUserIdentities();
  }

  onUnsetIdentities = () => {
    unsetUserIdentities();
    this.getUserIdentities();
    this.setState(() => (initialState));
  }

  onSetZI = () => {
    setZI(document.getElementById("zi").value);
    this.getUserIdentities();
  }

  onResetZI = () => {
    resetZI();
    this.getUserIdentities();
  }

  componentDidMount() {
    this.getUserIdentities();
  }
  render() {
      return (
        <>
        <LogLink />
        <div className="pt-10">
          <h3>User Identities</h3>
          <div className="flex justify-around">
            <div className="w-full max-w-104">
              {
                Object.keys(this.state).map((key,i) => {
                  if(key !== 'zi') {
                    return (<div className="mb-4" key={i}>
                      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={key}>
                        {key.toUpperCase()}
                      </label>
                      <input value={this.state[key]} name={key} onChange={this.onChange} aria-label={key}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" />
                    </div>)
                  }
                  return null;
                })
              }
            <div className="flex items-center justify-between">
              <button onClick={() => {this.onSetIdentities()}} 
              className="bg-secondary hover:bg-black text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                Set Identities
              </button>
              <button onClick={() => {this.onUnsetIdentities()}} 
              className="bg-secondary hover:bg-black text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                Unset
              </button>
            </div>
            </div>
            <div className="w-full max-w-104">
            <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor='zi'>
                      ZI
                    </label>
                    <input name='zi' onChange={this.onChange} value={this.state.zi} aria-label="zi"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id='zi' type="text" />
              </div>
            <div className="flex items-center justify-between mb-10">
              <button onClick={() => {this.onSetZI()}} className="bg-secondary hover:bg-black text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                Set ZI
              </button>
              <button onClick={() => {this.onResetZI()}} className="bg-secondary hover:bg-black text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                Reset ZI
              </button>
            </div>
            </div>
          </div>
        </div>
        </>
      )
    }
}

export default Identities
